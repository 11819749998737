<template>
  <v-form id="contact_form" novalidate="novalidate" class="col-12">
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          style="border:none;"
          hide-details="auto"
          prepend-icon="mdi-account"
          type="text"
          id="given_name"
          name="given_name"
          v-model="personalInfo.given_name"
          label="Prénom"
          :error-messages="givenNameErrors"
          @input="$v.personalInfo.given_name.$touch()"
          @blur="$v.personalInfo.given_name.$touch()"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          prepend-icon="mdi-account"
          type="text"
          id="name"
          name="name"
          v-model="personalInfo.name"
          label="Nom"
          :error-messages="nameErrors"
          @input="$v.personalInfo.name.$touch()"
          @blur="$v.personalInfo.name.$touch()"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          prepend-icon="mdi-map-marker-radius"
          type="text"
          id="street"
          name="street"
          v-model="personalInfo.address.street_address"
          label="Rue"
          :error-messages="StreetErrors"
          @input="$v.personalInfo.address.street_address.$touch()"
          @blur="$v.personalInfo.address.street_address.$touch()"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          prepend-icon="mdi-postage-stamp"
          type="text"
          id="zipcode"
          name="zipcode"
          v-model="personalInfo.address.postal_code"
          label="Code postal"
          :error-messages="ZipCodeErrors"
          @input="$v.personalInfo.address.postal_code.$touch()"
          @blur="$v.personalInfo.address.postal_code.$touch()"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          prepend-icon="mdi-city"
          type="text"
          id="city"
          name="city"
          v-model="personalInfo.address.locality"
          label="Ville"
          :error-messages="CityErrors"
          @input="$v.personalInfo.address.locality.$touch()"
          @blur="$v.personalInfo.address.locality.$touch()"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          prepend-icon="mdi-phone-classic"
          type="text"
          id="phone_number"
          name="phone_number"
          v-model="personalInfo.phone_number"
          label="Télèphone fixe"
          placeholder="+33123456789"
          :error-messages="phoneErrors"
          @input="$v.personalInfo.phone_number.$touch()"
          @blur="$v.personalInfo.phone_number.$touch()"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          prepend-icon="mdi-cellphone-basic"
          type="text"
          id="custom:mobile"
          name="custom:mobile"
          label="Télèphone portable"
          placeholder="+33610234564"
          v-model="personalInfo['custom:mobile']"
          :error-messages="mobileErrors"
          @input="$v.personalInfo['custom:mobile'].$touch()"
          @blur="$v.personalInfo['custom:mobile'].$touch()"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          prepend-icon="mdi-email"
          type="text"
          id="email"
          name="email"
          v-model="email"
          label="Email"
          placeholder="bob@myemail.fr"
          disabled
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-checkbox
          v-model="cgu"
          :error-messages="CguErrors"
          @input="$v.cgu.$touch()"
          @blur="$v.cgu.$touch()"
        >
          <template v-slot:label>
            <div @click.stop="">
              J’accepte les
              <a href="/CGU" target="_blank"
                >Conditions Générale d’Utilisation</a
              >
              pour continuer.
            </div>
          </template>
        </v-checkbox>
      </v-col>
    </v-row>

    <div class="justify-center">
      <v-btn
        type="button"
        class="btn-custom"
        variant="outline-primary"
        :loading="isLoading"
        :disabled="isLoading"
        @click="submit"
      >
        Modifier
        <template v-slot:isLoading>
          <span class="custom-loader">
            <v-icon light>mdi-cached</v-icon>
          </span>
        </template>
      </v-btn>
    </div>
  </v-form>
</template>
<style>
.v-text-field .v-label--active {
  transform: translateY(-24px) scale(0.75);
}
.v-text-field__slot,
.v-text-field__slot input {
  border: none;
}
</style>
<script>
import Swal from "sweetalert2";
import { REFRESH_AUTH } from "@/core/services/store/auth.module";
import { validationMixin } from "vuelidate";
import { required, helpers, sameAs } from "vuelidate/lib/validators";

const alpha = helpers.regex("alpha", /^[a-zA-ZÀ-ú \- s]+$/i);
const mobileNumber = helpers.regex("mobileNumber", /^(\+33|0)(6|7|9)\d{8}$/i);
const fixNumber = helpers.regex("fixNumber", /^(\+33|0)(1|2|3|4|5)\d{8}$/i);
const zipCode = helpers.regex(
  "zipCode",
  /^((0[1-9])|([1-8][0-9])|(9[0-8])|(2A)|(2B))[0-9]{3}$/i
);

export default {
  mixins: [validationMixin],
  validations: {
    cgu: { required, sameAs: sameAs(() => true) },
    personalInfo: {
      name: { required, alpha },
      given_name: { required, alpha },
      phone_number: { fixNumber },
      "custom:mobile": { required, mobileNumber },
      address: {
        locality: { required, alpha },
        street_address: { required },
        postal_code: { required, zipCode }
      }
    }
  },
  components: {},
  async mounted() {
    const currentUser = await this.$Amplify.Auth.currentAuthenticatedUser();
    this.currentUser = await this.$Amplify.Auth.userAttributes(currentUser);
    this.currentUser.forEach(item => {
      if (this.personalInfo[item.Name] !== undefined) {
        if (item.Name === "address") {
          this.personalInfo[item.Name] = JSON.parse(item.Value);
        } else {
          this.personalInfo[item.Name] =
            item.Value === undefined ? "" : item.Value;
        }
      }
    });
  },
  computed: {
    CguErrors() {
      const errors = [];
      if (!this.$v.cgu.$dirty) return errors;
      !this.$v.cgu.sameAs &&
        errors.push("Vous devez accepter les CGU pour utiliser le service ");
      return errors;
    },
    StreetErrors() {
      const errors = [];
      if (!this.$v.personalInfo.address.street_address.$dirty) return errors;
      !this.$v.personalInfo.address.street_address.required &&
        errors.push("La rue est obligatoire");
      return errors;
    },
    ZipCodeErrors() {
      const errors = [];
      if (!this.$v.personalInfo.address.postal_code.$dirty) return errors;
      !this.$v.personalInfo.address.postal_code.required &&
        errors.push("Le code postal est obligatoire");
      !this.$v.personalInfo.address.postal_code.zipCode &&
        errors.push("Le code postal n'est pas valide");
      return errors;
    },
    CityErrors() {
      const errors = [];
      if (!this.$v.personalInfo.address.locality.$dirty) return errors;
      !this.$v.personalInfo.address.locality.required &&
        errors.push("Le prénom est obligatoire");
      !this.$v.personalInfo.address.locality.alpha &&
        errors.push("La ville ne doit contenir que des lettres");
      return errors;
    },
    givenNameErrors() {
      const errors = [];
      if (!this.$v.personalInfo.given_name.$dirty) return errors;
      !this.$v.personalInfo.given_name.required &&
        errors.push("Le prénom est obligatoire");
      !this.$v.personalInfo.given_name.alpha &&
        errors.push("Le prénom ne doit contenir que des lettres");
      return errors;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.personalInfo.name.$dirty) return errors;
      !this.$v.personalInfo.name.required &&
        errors.push("Le nom est obligatoire");
      !this.$v.personalInfo.name.alpha &&
        errors.push("Le nom ne doit contenir que des lettres");

      return errors;
    },
    mobileErrors() {
      const errors = [];
      if (!this.$v.personalInfo["custom:mobile"].$dirty) return errors;
      !this.$v.personalInfo["custom:mobile"].required &&
        errors.push("Le numéro de portable est obligatoire");
      !this.$v.personalInfo["custom:mobile"].mobileNumber &&
        errors.push("Le numéros doit être sous la forme +33623456789");
      return errors;
    },
    phoneErrors() {
      const errors = [];
      if (!this.$v.personalInfo.phone_number.$dirty) return errors;
      !this.$v.personalInfo.phone_number.fixNumber &&
        errors.push("Le numéros doit être sous la forme +33123456789");
      return errors;
    }
  },
  data() {
    return {
      isLoading: false,
      email: this.$Amplify.Auth.user.attributes.email,
      cgu: false,
      personalInfo: {
        name: "",
        given_name: "",
        phone_number: "",
        "custom:mobile": "",
        address: {}
      }
    };
  },
  methods: {
    async submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      try {
        const currentUser = await this.$Amplify.Auth.currentAuthenticatedUser();
        if (
          this.personalInfo["phone_number"] !== "" &&
          this.personalInfo["phone_number"].indexOf("+33") === -1
        ) {
          this.personalInfo["phone_number"] =
            "+33" + this.personalInfo["phone_number"].substring(1);
        }
        if (
          this.personalInfo["custom:mobile"] !== "" &&
          this.personalInfo["custom:mobile"].indexOf("+33") === -1
        ) {
          this.personalInfo["custom:mobile"] =
            "+33" + this.personalInfo["custom:mobile"].substring(1);
        }
        this.isLoading = true;
        const tmpInfo = { ...this.personalInfo };
        tmpInfo["address"] = JSON.stringify(tmpInfo.address);
        await this.$Amplify.Auth.updateUserAttributes(currentUser, tmpInfo);
        await this.$store.dispatch(REFRESH_AUTH);
        this.isLoading = false;
        this.$emit("updateuser");

        /* Swal.fire({
           title: "",
           text: "Mise à jour effectuée",
           icon: "info",
           heightAuto: false
         });
         this.isLoading = false;
         this.$router.push({name: "Dashboard"});*/
      } catch (err) {
        Swal.fire({
          title: "",
          text: err.message,
          icon: "error",
          heightAuto: false
        });
      }
    }
  }
};
</script>
