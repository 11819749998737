<template>
  <div>
    <!-- Start Breadcrump Area  -->
    <div
      class="breadcrumb-area rn-bg-color ptb--60 bg_image bg_image--1"
      data-black-overlay="6"
    >
      <v-container>
        <v-row>
          <v-col lg="12">
            <div class="breadcrumb-inner pt--100 pt_sm--40 pt_md--50">
              <h2 class="heading-title">Informations personnelles</h2>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Breadcrump Area  -->

    <!-- Start team Area  -->
    <div class="rn-team-area bg_color--1 ptb--120">
      <v-container>
        <v-row class="d-flex justify-center">
          <FormAccount v-on:updateuser="updateUser()"></FormAccount>
        </v-row>
        <!-- End row -->
      </v-container>
    </div>
    <!-- Start team Area  -->
  </div>
</template>
<script>
import FormAccount from "./widget/FormAccount";
import Swal from "sweetalert2";

export default {
  components: {
    FormAccount
  },
  methods: {
    updateUser() {
      Swal.fire({
        title: "",
        text: "Mise à jour effectuée",
        icon: "info",
        heightAuto: false
      });
      this.$gtm.trackEvent({
        category: "Profile",
        action: "Update",
        label: "update",
        value: "click"
      });
      this.$router.push({ name: "Dashboard" });
    }
  }
};
</script>
